import React from "react";
import { connect } from "react-redux";

import TestModal from "./TestModal";
import SpinnerModal from "./SpinnerModal";
import DepositModal from "./DepositModal";
import RegisterModal from "./RegisterModal";
import WithdrawalModal from "./WithdrawalModal";
import CreditLimitModal from "./CreditLimitModal";
import CreateSellerModal from "./CreateSellerModal";

import CreateOutletModal from "./CreateOutletModal";
import AmendDepositModal from "./AmendDepositModal";
import UploadAirtimeModal from "./UploadAirtimeModal";
import AddSelfServiceModal from "./AddSelfServiceModal";
import ChangeUserNameModal from "./ChangeUserNameModal";
import RegisterDeviceModal from "./RegisterDeviceModal";
import CreateMerchantModal from "./CreateMerchantModal";
import CreateCorporateMerchantModal from "./CreateCorporateMerchantModal";
import RegisterWUCUserModal from "./RegisterWUCUserModal";
import RegisterSIMCardModal from "./RegisterSIMCardModal";
import PettyCashRequestModal from "./PettyCashRequestModal";
import CreateCommissionModal from "./CreateCommissionModal";
import MerchantAgreementModal from "./MerchantAgreementModal";
import AssignSIMToDeviceModal from "./AssignSIMToDeviceModal";
import CommissionDepositModal from "./CommissionDepositModal";
import AdjustmentDepositModal from "./AdjustmentDepositModal";
import CustomerITReportModal from "./CustomerITReportSpinner";
import MerchantStatementModal from "./MerchantStatementSpinner";
import ChangeUserPasswordModal from "./ChangeUserPasswordModal";
import RegisterSystemUserModal from "./RegisterSystemUserModal";

import AdhocDevicePaymentModal from "./AdhocDevicePaymentModal";
import ManualDevicePaymentModal from "./ManualDevicePaymentModal";
import AmendDeviceContractModal from "./AmendDeviceContractModal";
import PettyCashAmendRequestModal from "./PettyCashAmendRequestModal";
import IntraMerchantTransferModal from "./IntraMerchantTransferModal";
import ReversalReportSpinnerModal from "./ReversalReportSpinnerModal";
import UnassignSIMFromDeviceModal from "./UnassignSIMFromDeviceModal";
import CreateSelfServiceOutletModal from "./CreateSelfServiceOutletModal";
import RegisterMechantComputerModal from "./RegisterMechantComputerModal";
import RegisterDeviceWithOutletModal from "./RegisterDeviceWithOutletModal";
import MerchantFinancialsSpinnerModal from "./MerchantFinancialsSpinnerModal";
import BPCReversalsReportSpinnerModal from "./BPCReversalsReportSpinnerModal";
import BPCDailySalesReportSpinnerModal from "./BPCDailySalesReportSpinnerModal";
import OozeDailySalesReportSpinnerModal from "./OozeDailySalesReportSpinnerModal";
import KazangDailySalesReportSpinnerModal from "./KazangDailySalesReportSpinnerModal";

import PrepaidPlusFinancialsSpinnerModal from "./PrepaidPlusFinancialsSpinnerModal";
import ChoppiesMonthlyReportSpinnerModal from "./ChoppiesMonthlyReportSpinnerModal";
import SalesAndCommissionReportSpinnerModal from "./SalesAndCommissionReportSpinnerModal";
import DeviceTransactionsReportSpinnerModal from "./DeviceTransactionsReportSpinnerModal";
import GroupSalesAndCommissionReportSpinnerModal from "./GroupSalesAndCommissionReportSpinnerModal";
import MerchantContractDepositsReportSpinnerModal from "./MerchantContractDepositsReportSpinnerModal";
import ChoppiesSalesAndCommissionReportSpinnerModal from "./ChoppiesSalesAndCommissionReportSpinnerModal";
import FinancialsReportSpinnerModal from "./FinancialsReportSpinnerModal";
import MerchantActivityReportModal from "./MerchantActivityReportModal";
import BalanceTriggerValueModal from "./BalanceTriggerValueModal";
import ProviderTrigerDepositModal from "./ProviderTrigerDepositModal";

const modalLookup = {
  TestModal,
  SpinnerModal,
  DepositModal,
  RegisterModal,
  WithdrawalModal,
  CreditLimitModal,
  AmendDepositModal,
  CreateSellerModal,
  CreateOutletModal,
  UploadAirtimeModal,
  ChangeUserNameModal,
  AddSelfServiceModal,
  CreateMerchantModal,
  CreateCorporateMerchantModal,
  RegisterDeviceModal,
  RegisterWUCUserModal,
  RegisterSIMCardModal,
  PettyCashRequestModal,
  CreateCommissionModal,
  AdjustmentDepositModal,
  CustomerITReportModal,
  MerchantStatementModal,
  MerchantAgreementModal,
  AssignSIMToDeviceModal,
  CommissionDepositModal,
  ChangeUserPasswordModal,
  RegisterSystemUserModal,
  AdhocDevicePaymentModal,
  ManualDevicePaymentModal,
  AmendDeviceContractModal,
  IntraMerchantTransferModal,
  PettyCashAmendRequestModal,
  ReversalReportSpinnerModal,
  UnassignSIMFromDeviceModal,
  CreateSelfServiceOutletModal,
  RegisterMechantComputerModal,
  RegisterDeviceWithOutletModal,
  MerchantFinancialsSpinnerModal,
  BPCReversalsReportSpinnerModal,
  BPCDailySalesReportSpinnerModal,
  OozeDailySalesReportSpinnerModal,
  KazangDailySalesReportSpinnerModal,
  PrepaidPlusFinancialsSpinnerModal,
  ChoppiesMonthlyReportSpinnerModal,
  SalesAndCommissionReportSpinnerModal,
  DeviceTransactionsReportSpinnerModal,
  GroupSalesAndCommissionReportSpinnerModal,
  MerchantContractDepositsReportSpinnerModal,
  ChoppiesSalesAndCommissionReportSpinnerModal,
  FinancialsReportSpinnerModal,
  BalanceTriggerValueModal,
  ProviderTrigerDepositModal,
  MerchantActivityReportModal,
};

const mapState = (state) => ({
  currentModal: state.modals,
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
